import React from "react"
import Layout from "../components/layout"
import Posts from "../components/posts"
import SEO from "../components/seo"
import H1 from "../components/h1"

const title = "Wycieczki i Festiwale"

const SwietaFestiwale = () => (
    <Layout>
        <SEO title={title} />
        <H1 title={title} page="card"></H1>
        <Posts category="swieta-festiwale" />
    </Layout>
)

export default SwietaFestiwale
